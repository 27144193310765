import React from 'react';
import { Box, Typography, Button } from '@mui/material';

const FinalRecovery = () => {

    return (
        <Box
            sx={{
                minHeight: '600px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: '#4FC3F7',
                color: '#ffffff',
                fontFamily: 'monospace',
                overflow: 'hidden',
                position: 'relative',
                padding: '40px',
            }}
        >
            <Box
                sx={{
                    backgroundColor: '#4FC3F7',
                    width: '100%',
                    textAlign: 'left',
                }}
            >
                <Typography variant="h5" sx={{ paddingLeft: "40px", fontSize: "1.5rem", fontColor: "#29B6F6", marginTop: "80px", }}>We Build <Typography sx={{color: "#FFB300", fontSize: "48px", lineHeight: "48px", }}>Custom Web Stores</Typography></Typography>
                <Typography variant="h5" sx={{ paddingLeft: "40px", fontSize: "1.5rem", fontColor: "#29B6F6", marginTop: "20px", }}>"With a webstore, your shop is open 24/7. Customers can browse and purchase your products at any time, leading to increased sales opportunities, even when your physical store is closed."</Typography>
                {/* <Button variant="contained" sx={{ marginTop: '40px', marginLeft: '40px', marginBottom: '80px', backgroundColor: '#29B6F6', textAlign: 'center!important'}}>
                    Learn More
                </Button> */}
            </Box> 
        </Box>
    );
};

export default FinalRecovery;
